import Entity from '_entities/base/EntityNew'

export const CoordinatesSchema = {
    name: 'Coordinates',
    properties: {
        lat: { type: 'float' },
        lon: { type: 'float' },
    },
}

class Coordinates extends Entity {
    get schema() {
        return CoordinatesSchema
    }

    static fieldLabels = {
        lat: 'Широта',
        lon: 'Долгота',
    }
}

export default Coordinates
