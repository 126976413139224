import { request } from '_api/v1/base/behemoth/client'

export const getPollList = async (params) => {
    return await request('GET', 'polls/', params)
}

export const sendPollResult = async (data) => {
    return await request('POST', 'poll_results/', data)
}

export const getPollResult = async (data) => {
    // respondent_id__in[], premise_id__in[], account_id__in[]
    return await request('POST', 'polls/get_poll_results/', data)
}

export const sendBulletin = async (data) => {
    // respondent_id, account_id, files[]
    return await request('POST', 'polls/send_bulletin/', data)
}
