import { forEach as _forEach, map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import ServiceLink from '_entities/service/ServiceLink'
import ServiceSubsection from '_entities/service/ServiceSubsection'
import ServiceTag from '_entities/service/ServiceTag'
import ServicePartnerPromotion from '_entities/service/ServicePartnerPromotion'

import BuildingLink from '_entities/building/BuildingLink'
import CompanyLink from '_entities/company/CompanyLink'
import Doc from '_entities/media/Doc'
import Office from '_entities/office/Office'
import Photo from '_entities/media/Photo'

import TicketDepartmentLink from '_entities/ticket/TicketDepartmentLink'
import TicketKindLink from '_entities/ticket/TicketKindLink'
import TicketTypeLink from '_entities/ticket/TicketTypeLink'

export const ServiceSchema = {
    name: 'Service',
    primaryKey: 'id',
    properties: {
        buildings: { type: 'BuildingLink[]', entity: BuildingLink, default: () => [] },
        companies: { type: 'CompanyLink[]', entity: CompanyLink, default: () => [] },
        companyReceiver: { type: 'CompanyLink', entity: CompanyLink },
        description: { type: 'string' },
        documents: { type: 'Doc[]', entity: Doc, default: () => [] },
        elements: { type: 'mixed[]', default: () => [] },
        enableLocationMatchCheck: { type: 'bool' },
        forHelpSection: { type: 'bool' },
        forMainSection: { type: 'bool' },
        forUsefulSection: { type: 'bool' },
        icon: { type: 'Photo', entity: Photo },
        id: { type: 'string' },
        image: { type: 'Photo', entity: Photo },
        imageBanner: { type: 'Photo', entity: Photo },
        imageDetail: { type: 'Photo', entity: Photo },
        imageGallery: { type: 'Photo[]', entity: Photo, default: () => [] },
        isActive: { type: 'bool' },
        isAutoInvoice: { type: 'bool' },
        isNew: { type: 'bool' },
        isRated: { type: 'bool' },
        isRecommended: { type: 'bool' },
        mainPage: { type: 'bool' },
        minimalCost: { type: 'int' },
        name: { type: 'string' },
        office: { type: 'Office', entity: Office },
        order: { type: 'int' },
        parentService: { type: 'ServiceLink', entity: ServiceLink },
        paymentRequired: { type: 'bool' },
        promotion: { type: 'ServicePartnerPromotion', entity: ServicePartnerPromotion, default: () => new ServicePartnerPromotion() },
        resultText: { type: 'string' },
        siteLink: { type: 'string' },
        serviceType: { type: 'string' },
        showTicketDatePlannedCompletion: { type: 'bool' },
        subsections: { type: 'ServiceSubsection[]', entity: ServiceSubsection, default: () => [] },
        subtitle: { type: 'string' },
        title: { type: 'string' },
        text: { type: 'string' },
        tags: { type: 'ServiceTag[]', entity: ServiceTag, default: () => [] },
        ticketDepartment: { type: 'TicketDepartmentLink', entity: TicketDepartmentLink },
        ticketKind: { type: 'TicketKindLink', entity: TicketKindLink },
        ticketType: { type: 'TicketTypeLink', entity: TicketTypeLink },
        waitingTimePayment: { type: 'int' },
    },
}

class Service extends Entity {
    get schema() {
        return ServiceSchema
    }

    get dataMap() {
        return {
            banner_image: 'imageBanner',
            company_receiver: 'companyReceiver',
            enable_location_match_check: 'enableLocationMatchCheck',
            for_help_section: 'forHelpSection',
            for_main_section: 'forMainSection',
            for_useful_section: 'forUsefulSection',
            image_detail: 'imageDetail',
            image_gallery: 'imageGallery',
            is_active: 'isActive',
            is_auto_invoice: 'isAutoInvoice',
            is_new: 'isNew',
            is_rated: 'isRated',
            is_recommended: 'isRecommended',
            main_page: 'mainPage',
            minimal_cost: 'minimalCost',
            parent_service: 'parentService',
            payment_required: 'paymentRequired',
            result_text: 'resultText',
            service_type: 'serviceType',
            site_link: 'siteLink',
            show_ticket_date_planned_completion: 'showTicketDatePlannedCompletion',
            ticket_department: 'ticketDepartment',
            ticket_kind: 'ticketKind',
            ticket_type: 'ticketType',
            waiting_time_payment: 'waitingTimePayment',
        }
    }

    static fieldLabels = {
        buildings: 'Дома',
        companies: 'Компании',
        icon: 'Иконка',
        id: 'ID',
        image: 'Изображение',
        imageDetail: 'Изображение (детально)',
        imageBanner: 'Изображение баннера',
        isActive: 'Активен',
        isNew: 'Новинка',
        isRated: 'Учитывать в CSI',
        isRecommended: 'Рекомендованный',
        minimalCost: 'Минимальная цена',
        name: 'Название',
        office: 'Офис',
        order: 'Порядок',
        promotion: 'Бонус от компании-партнера',
        serviceType: 'Тип',
        siteLink: 'Сайт',
        showTicketDatePlannedCompletion: 'Передавать дату планируемого выполнения в МПЖ',
        subsections: 'Разделы',
        tags: 'Тэги',
        ticketDepartment: 'Отдел',
        ticketKind: 'Вид',
        ticketType: 'Тип',
        // Info
        description: 'Описание',
        documents: 'Документы',
        imageGallery: 'Галерея',
        subtitle: 'Подзаголовок',
        resultText: 'Текст описания результата',
        title: 'Заголовок сервиса',
        text: 'Текст',
        // Constructor
        companyReceiver: 'Компания получатель платежа',
        elements: 'Элементы',
        enableLocationMatchCheck: 'Проверять компанию-получателя в сервисе',
        isAutoInvoice: 'Порядок формирования счета',
        parentService: 'Родительский сервис',
        paymentRequired: 'Требуется оплата',
        waitingTimePayment: 'Время ожидания оплаты, минут',
        forHelpSection: 'Показывать в разделе "Помощь"',
        forUsefulSection: 'Показывать в разделе "Полезная информация"',
        forMainSection: 'Показывать в основном списке сервисов',
        mainPage: 'Показывать на главной странице помещения',
    }

    static invoiceVariants = [
        { text: 'Счет выставляется администратором', value: false },
        { text: 'Счет выставляется автоматически', value: true },
    ]

    toServer() {
        const info = {}

        if (this.serviceType === 'info') {
            info.subtitle = this.subtitle
            info.description = this.description
            info.image_gallery = _map(this.imageGallery, (item) => item.id)
            info.documents = _map(this.documents, (item) => item.id)
        }

        const constructor = {}

        if (this.serviceType === 'constructor') {
            constructor.company_receiver = this.companyReceiver?.id
            constructor.is_auto_invoice = this.isAutoInvoice
            constructor.payment_required = this.paymentRequired
            constructor.waiting_time_payment = this.waitingTimePayment
            constructor.enable_location_match_check = this.enableLocationMatchCheck

            const elements = []
            _forEach(this.elements, (element, index) => {
                // TODO: element.toServer(element) > element.toServer()
                element.order = index + 1
                elements.push(element.toServer(element))
            })
            constructor.elements = elements
        }

        const keyIssueService = {}

        if (this.serviceType === 'key_issue') {
            keyIssueService.title = this.title
            keyIssueService.text = this.text
            keyIssueService.description = this.description
            keyIssueService.result_text = this.resultText

            const elements = []
            _forEach(this.elements, (element, index) => {
                // TODO: element.toServer(element) > element.toServer()
                elements.order = index + 1
                elements.push(element.toServer(element))
            })
            keyIssueService.calendar_event_element = elements?.[0] || null
        }

        return {
            buildings: _map(this.buildings, (item) => item.id),
            companies: _map(this.companies, (item) => item.id),
            icon: this.icon?.id,
            image: this.image?.id,
            image_detail: this.imageDetail?.id,
            is_active: !!this.isActive,
            is_new: !!this.isNew,
            is_recommended: this.isRecommended,
            for_help_section: !!this.forHelpSection,
            for_useful_section: !!this.forUsefulSection,
            for_main_section: !!this.forMainSection,
            is_rated: !!this.isRated,
            main_page: !!this.mainPage,
            minimal_cost: this.minimalCost ? Math.round(this.minimalCost) : null,
            name: this.name,
            office: this.office?.id || null,
            order: this.order,
            parent_service: this.parentService?.id || null,
            promotion: this.promotion?.id || null,
            service_type: this.serviceType,
            site_link: this.siteLink,
            show_ticket_date_planned_completion: this.showTicketDatePlannedCompletion,
            subsections: _map(this.subsections, (item) => item.id),
            tags: _map(this.tags, (item) => item.id),
            ticket_department: this.ticketDepartment?.id || null,
            ticket_kind: this.ticketKind?.id || null,
            ticket_type: this.ticketType?.id || null,
            banner_image: this.imageBanner?.id || null,
            enable_location_match_check: !!this.enableLocationMatchCheck,
            // Info
            ...info,
            // Constructor
            ...constructor,
            // keyIssue
            ...keyIssueService,
        }
    }

    get filterName() {
        const companies = _map(this.companies, (company) => {
            return company.name
        })

        const buildings = _map(this.buildings, (building) => {
            return building?.address?.full
        })
        let name = this.name
        if (companies.length) {
            name += ` (${companies.join(', ')})`
        }
        if (buildings.length) {
            name += ` (${buildings.join(', ')})`
        }

        return name
    }
}

export default Service
