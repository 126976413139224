import Entity from '_entities/base/EntityNew'

import Address from '_entities/address/Address'

export const PremiseLinkSchema = {
    name: 'PremiseLink',
    primaryKey: 'id',
    properties: {
        address: { type: 'Address', entity: Address },
        id: { type: 'string' },
        number: { type: 'string' },
    },
}
class PremiseLink extends Entity {
    get schema() {
        return PremiseLinkSchema
    }

    static fieldLabels = {
        address: 'Адрес',
        id: 'ID',
        number: 'Номер',
    }

    get displayName() {
        return this.address?.short || this.address?.full || 'Адрес отсутствует'
    }
}

export default PremiseLink
