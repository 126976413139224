<template>
    <v-app :style="{background: $vuetify.theme.themes[theme].background}">
        <template v-if="isInitializing">
            <CContainer center fluid class="secondary">
                <v-progress-circular indeterminate color="white" />
            </CContainer>
        </template>
        <template v-else>
            <template v-if="isMaintenance">
                <CContainer center fluid class="secondary">
                    <FMaintenancePanel />
                </CContainer>
            </template>

            <template v-else>
                <CNavigationDrawer v-if="isHomeScreen && isShownNavigationBar" />

                <CSystemBar />

                <CAppBar
                    v-if="isHomeScreen"
                    :is-short="isMobile"
                />

                <FNotificationCenter v-if="isHomeScreen" />

                <v-main>
                    <router-view />
                </v-main>

                <CSnackbar />

                <v-dialog v-model="offLine" persistent max-width="290">
                    <v-card>
                        <v-card-title class="headline">
                            Network error
                        </v-card-title>

                        <v-card-text>Приложение продолжит работу после соединения с Интернетом.</v-card-text>
                    </v-card>
                </v-dialog>

                <FIncomingCallDialog />

                <FIncomingCallDialogNew />

                <FPhonePanelNew />
                <audio id="audio_remote" autoplay="autoplay" />
            </template>
        </template>

        <FMaintenanceRoot />
    </v-app>
</template>

<script>
    import { mapState } from 'vuex'

    import CAppBar from '_common/components/CAppBar'
    import CContainer from '_common/components/CContainer'
    import CNavigationDrawer from '_common/components/CNavigationDrawer'
    import CSnackbar from '_common/components/CSnackbar'
    import CSystemBar from '_common/components/CSystemBar'

    import FIncomingCallDialog from '_features/incomingCall/components/FIncomingCallDialog'
    import FIncomingCallDialogNew from '_features/sipCall/components/FIncomingCallDialog'
    import FMaintenancePanel from '_features/maintenanceWork/components/FMaintenancePanel'
    import FMaintenanceRoot from '_features/maintenanceWork/components/FMaintenanceRoot'
    import FNotificationCenter from '_features/notificationCenter/components/FNotificationCenter'
    import FPhonePanelNew from '_features/sipCall/components/FPhonePanelNew'
    import { PERMISSION_MODULES, PERMISSIONS } from '_features/permissions/constants'

    export default {
        name: 'App',

        components: {
            CAppBar,
            CContainer,
            CNavigationDrawer,
            CSnackbar,
            CSystemBar,
            FIncomingCallDialog,
            FIncomingCallDialogNew,
            FMaintenancePanel,
            FMaintenanceRoot,
            FNotificationCenter,
            FPhonePanelNew,
        },

        data: () => ({
            isAppLoading: true,
            isInitializing: true,
            isPermissionsChecked: false,
            isDataLoaded: false,
            isMaintenanceChecked: false,
            isSystemBootstrapped: false,
            routeFixTimerId: null,
        }),

        computed: {
            ...mapState('global', {
                offLine: (state) => !state.onLine,
            }),

            ...mapState('auth', {
                isSignedOut: (state) => !!state.isSignedOut,
                isAuthorized: (state) => !!state.accessToken,
                userId: (state) => state.userId,
            }),

            theme() {
                return this.$vuetify.theme.dark ? 'dark' : 'light'
            },

            isAuthScreen() {
                return this.$route.matched.some((route) => route.name === 'Auth')
            },

            isHomeScreen() {
                return this.$route.matched.some((route) => route.name === 'Home')
            },

            isMobile() {
                return this.$vuetify.breakpoint.smAndDown
            },

            isShownNavigationBar() {
                const allowModules = this.$store.getters['permissions/hasModulePermission']([
                    PERMISSION_MODULES.TICKET,
                    PERMISSION_MODULES.LOCATION,
                    PERMISSION_MODULES.DEPARTMENT,
                    PERMISSION_MODULES.CALL,
                    PERMISSION_MODULES.NEWSLETTER,
                    PERMISSION_MODULES.CHAT_BOT,
                    PERMISSION_MODULES.DOCUMENT,
                    PERMISSION_MODULES.LOCATION,
                    PERMISSION_MODULES.NEWS,
                    PERMISSION_MODULES.SERVICE,
                    PERMISSION_MODULES.STORAGE,
                    PERMISSION_MODULES.ANALYTICS,
                ])

                const allowRights = this.$hasPermission([
                    PERMISSIONS.CAN_VIEW_TICKET_SETTINGS,
                    PERMISSIONS.CAN_VIEW_SCREPORT,
                    PERMISSIONS.CAN_VIEW_AUTH_GROUP,
                    PERMISSIONS.CAN_VIEW_NEED_ANSWER_SECTION,
                    PERMISSIONS.CAN_VIEW_DEPARTMENT_COMPANY,
                    PERMISSIONS.CAN_VIEW_DOCUMENTS,
                    PERMISSIONS.CAN_VIEW_SLOT,
                    PERMISSIONS.CAN_VIEW_MAINTENANCE_WORK_EVENT,
                    PERMISSIONS.CAN_VIEW_EXPERIMENTAL_FEATURES,
                ])

                return allowModules || allowRights
            },

            isEmptyRoute() {
                // В некоторых случаях переадресация на новой вкладке ломается.
                // В этом случае isHomeScreen определяется некорректно и переадресацию на домашнюю страницу делать не нужно.
                // TODO: Исправить мистическое поведение Vue Router
                return this.$route.name === null
            },

            isMaintenance() {
                return this.$store.state.maintenanceWork?.isMaintenance
            },

            needRestartApplication() {
                return this.$store.state.maintenanceWork?.needRestartApplication
            },
        },

        watch: {
            isInitializing: {
                handler(isInitializing) {
                    if (!isInitializing) {
                        this.checkAuthorization()
                    }
                },
                immediate: true,
            },
            isSignedOut: {
                handler(isSignedOut) {
                    if (isSignedOut) {
                        this.toAuth()
                    }
                },
                immediate: true,
            },
            isAuthorized: {
                handler() {
                    if (this.isAuthorized) {
                        this.loadData()
                    }
                },
                immediate: true,
            },

            isDataLoaded: {
                handler(isDataLoaded) {
                    if (isDataLoaded) {
                        this.checkPermissions()
                    }
                },
                immediate: true,
            },

            isPermissionsChecked: {
                handler(isPermissionsChecked) {
                    if (isPermissionsChecked) {
                        this.isInitializing = false
                    }
                },
                immediate: true,
            },

            isSystemBootstrapped: {
                handler() {
                    if (this.isSystemBootstrapped) {
                        this.checkMaintenance()
                    }
                },
                immediate: true,
            },

            isMaintenanceChecked: {
                handler() {
                    if (this.isMaintenanceChecked && !this.isMaintenance) {
                        this.bootstrapApp()
                    } else {
                        this.isInitializing = false
                    }
                },
            },

            needRestartApplication: {
                handler() {
                    if (this.needRestartApplication) {
                        this.reloadApp()
                    }
                },
            },
        },

        // Application initialization. Step 2 - App created
        created() {
            this.bootstrapSystem()
        },

        destroyed() {
            clearTimeout(this.routeFixTimerId)

            window.removeEventListener('focus', this.appFocusIn)
            window.removeEventListener('blur', this.appBlur)
        },

        methods: {
            async bootstrapSystem() {
                setTimeout(() => {
                    if (this.isAppLoading) {
                        this.reloadApp()
                    }
                }, 60 * 1000)

                // Application initialization. Step 3 - Listen Software Update
                await this.listenSoftwareUpdate()
                // Application initialization. Step 4 - Listen OnLine State
                await this.listenOnLineState()

                this.isSystemBootstrapped = true
            },

            async checkMaintenance() {
                await this.$store.dispatch('maintenanceWork/fetchMaintenanceStatus')

                setInterval(() => {
                    this.$store.dispatch('maintenanceWork/fetchMaintenanceStatus')
                }, 30 * 60 * 1000) // Проверка статуса техработ каждые 30 минут

                this.isMaintenanceChecked = true
            },

            async bootstrapApp() {
                // Application initialization. Step 5 - Restoring Access
                await this.$store.dispatch('auth/restoreAccess')

                await new Promise((resolve) => setTimeout(() => resolve(), 500))

                if (!this.isAuthorized) {
                    this.isInitializing = false
                }

                await this.$store.dispatch('sync/getSentryDSN')

                setInterval(() => {
                    this.$store.dispatch('sync/getSentryDSN')
                }, 24 * 60 * 60 * 1000) // Перепроверка DSN каждые сутки

                window.addEventListener('focus', this.appFocusIn)
                window.addEventListener('blur', this.appBlur)

                this.isAppLoading = false
            },

            listenSoftwareUpdate() {
                document.addEventListener('serviceWorkerUpdated', this.onServiceWorkerUpdated, { once: true })

                navigator.serviceWorker.addEventListener('controllerchange', () => {
                    if (this.$store.state.softwareUpdate.isUpdating) {
                        return
                    }

                    this.$store.commit('softwareUpdate/setUpdating', true)

                    this.reloadApp()
                })
            },

            onServiceWorkerUpdated(event) {
                // Обновление найдено
                this.$store.dispatch('softwareUpdate/setServiceWorkerRegistration', event.detail)
            },

            listenOnLineState() {
                this.$store.commit('global/setOnLineState', navigator.onLine)

                window.addEventListener('online', () => {
                    this.$store.commit('global/setOnLineState', navigator.onLine)
                })

                window.addEventListener('offline', () => {
                    this.$store.commit('global/setOnLineState', navigator.onLine)
                })
            },

            async loadData() {
                const promises = [
                    this.$store.dispatch('auth/fetchCurrentWorker'),
                    this.$store.dispatch('permissions/fetchPermissions'),
                    this.$store.dispatch('ticketStages/fetchTicketStages'),
                    this.$store.dispatch('buildings/fetchBuildings', { page: 1, page_size: Number.MAX_SAFE_INTEGER }),
                    this.$store.dispatch('queue/fetchQueues'),
                    this.$store.dispatch('departments/fetchDepartments', { page: 1, page_size: Number.MAX_SAFE_INTEGER }),
                ]

                await Promise.all(promises)

                await this.$store.dispatch('callQueue/restoreCallQueue')
                await this.$store.dispatch('callQueue/fetchQueueStatus', { workerId: this.userId })
                await this.$store.dispatch('timeManagement/getCurrentTimeEntry', this.userId)

                this.$logger.logEvent({ goal: 'Вход в приложение' })

                this.isDataLoaded = true
            },

            hasPermission(permissions) {
                return this.$store.getters['permissions/hasPermission'](permissions)
            },

            checkPermissions() {
                const permissions = this.$route?.meta?.permissions

                const isAllowed = permissions ? this.hasPermission(permissions) : true

                if (!isAllowed) {
                    this.$router.push({ name: 'Home' })
                }

                this.isPermissionsChecked = true
            },

            checkAuthorization() {
                // Fix для маршрутизации
                // После того, как isInitializing === false происходит рендер шаблона (в template срабатывает v-else)
                // и соотв. происходит рендер компонента router-view и происходит переход на нужный маршрут
                // Параллельно с этой логикой запускается checkAuthorization
                // Проблема в том, что рендер и метод checkAuthorization запускаются параллельно
                // По этому добавлен таймер, чтобы рендер отработал быстрее и this.$route был правильный
                this.routeFixTimerId = setTimeout(() => {
                    if (!this.isHomeScreen && !this.isEmptyRoute) {
                        this.$router.push({ name: 'Home' })
                    }
                }, 2000)
            },

            // -----------------------------------------------------------------

            toAuth() {
                if (!this.isAuthScreen) {
                    this.$router.push({ name: 'Auth' })
                }
            },

            appFocusIn() {
                this.$store.dispatch('maintenanceWork/fetchMaintenanceStatus')
                this.$store.commit('global/setAppActiveStatus', true)
            },

            appBlur() {
                this.$store.commit('global/setAppActiveStatus', false)
            },

            reloadApp() {
                location.reload()
            },
        },
    }
</script>
