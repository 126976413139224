import Entity from '_entities/base/EntityNew'

import Doc from '_entities/media/Doc'
import TicketTypeParentLink from '_entities/ticket/TicketTypeParentLink'
import User from '_entities/user/User'

export const TicketDepartmentSchema = {
    name: 'TicketDepartment',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        color: { type: 'string', optional: true },
        id: { type: 'string' },
        instruction: { type: 'Doc', entity: Doc, optional: true },
        isAvailable: { type: 'bool', default: false },
        level: { type: 'int', default: 1 },
        name: { type: 'string' },
        order: { type: 'int' },
        parent: { type: 'TicketTypeParentLink', entity: TicketTypeParentLink },
        isActive: { type: 'bool', default: false },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
    },
}

class TicketDepartment extends Entity {
    get schema() {
        return TicketDepartmentSchema
    }

    get dataMap() {
        return {
            is_available: 'isAvailable',
            is_active: 'isActive',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
        }
    }

    static fieldLabels = {
        code: 'Код',
        color: 'Цвет',
        instruction: 'Инструкция',
        id: 'ID',
        isAvailable: 'Доступен',
        name: 'Название',
        order: 'Порядок',
    }

    toArray() {
        return {
            ...super.toArray(),
            deleted_by: this.deletedBy?.id,
            instruction: this.instruction?.id || null,
            level: this.level,
            parent: this.parent?.id || null,
        }
    }
}

export default TicketDepartment
