import Entity from '_entities/base/EntityNew'

import Photo from '_entities/media/Photo'

export const UserLinkSchema = {
    name: 'UserLink',
    primaryKey: 'id',
    properties: {
        email: { type: 'string' },
        firstName: { type: 'string' },
        id: { type: 'string' },
        lastName: { type: 'string' },
        middleName: { type: 'string' },
        photo: { type: 'Photo', entity: Photo },
        phone: { type: 'string' },
        user_id: { type: 'string' },
    },
}

class UserLink extends Entity {
    get schema() {
        return UserLinkSchema
    }

    get dataMap() {
        return {
            first_name: 'firstName',
            last_name: 'lastName',
            middle_name: 'middleName',
        }
    }

    static fieldLabels = {
        email: 'Email',
        firstName: 'Имя',
        id: 'ID',
        lastName: 'Фамилия',
        middleName: 'Отчество',
        photo: 'Аватар',
        phone: 'Телефон',
        user_id: 'userId',
    }

    get fullName() {
        const names = []

        const lastName = this.lastName ? this.lastName.trim() : null
        const firstName = this.firstName ? this.firstName.trim() : null
        const middleName = this.middleName ? this.middleName.trim() : null

        if (lastName) {
            names.push(lastName)
        }
        if (firstName) {
            names.push(firstName)
        }
        if (middleName) {
            names.push(middleName)
        }

        return names.join(' ')
    }
}

export default UserLink
