import { map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import ApplicantFeedback from '_entities/applicantFeedback/ApplicantFeedback'
import Approval from '_entities/approval/Approval'
import BuildingLink from '_entities/building/BuildingLink'
import ChatLink from '_entities/chat/ChatLink'
import ChecklistElement from '_entities/checklist/ChecklistElement'
import Coordinates from '_entities/coordinates/Coordinates'
import CsiFeedback from '_entities/csiFeedback/CsiFeedback'
import Doc from '_entities/media/Doc'
import Photo from '_entities/media/Photo'
import PlacementLink from '_entities/placement/PlacementLink'
import PremiseLink from '_entities/premise/PremiseLink'
import ResidentialComplex from '_entities/residentialComplex/ResidentialComplex'
import TicketDepartmentLink from '_entities/ticket/TicketDepartmentLink'
import TicketInspectionInfo from '_entities/ticket/TicketInspectionInfo'
import TicketKindLink from '_entities/ticket/TicketKindLink'
import TicketLink from '_entities/ticket/TicketLink'
import TicketSCAcceptanceInfo from '_entities/ticket/TicketSCAcceptanceInfo'
import TicketSource from '_entities/ticket/TicketSource'
import TicketStageLink from '_entities/ticket/TicketStageLink'
import TicketType from '_entities/ticket/TicketType'
import RoomLink from '_entities/room/RoomLink'
import EntranceLink from '_entities/entrance/EntranceLink'
import SectorLink from '_entities/sector/SectorLink'
import Service from '_entities/service/Service'
import UserLink from '_entities/user/UserLink'

export const TicketSchema = {
    name: 'Ticket',
    primaryKey: 'id',
    properties: {
        actualAmount: { type: 'float' },
        additionalReviewers: { type: 'UserLink[]', entity: UserLink, default: [] },
        amountRequired: { type: 'bool' },
        applicant: { type: 'UserLink', entity: UserLink },
        applicantChat: { type: 'ChatLink', entity: ChatLink },
        applicantFeedbacks: { type: 'ApplicantFeedback[]', entity: ApplicantFeedback, default: [] },
        approval: { type: 'Approval', entity: Approval },
        auditors: { type: 'UserLink[]', entity: UserLink, default: [] },
        building: { type: 'BuildingLink', entity: BuildingLink },
        chatResponsible: { type: 'UserLink', entity: UserLink },
        checklistData: { type: 'ChecklistElement[]', entity: ChecklistElement, default: () => [] },
        childTickets: { type: 'TicketLink[]', entity: TicketLink, default: [] },
        commissionRate: { type: 'int' },
        commissionSum: { type: 'float' },
        coordinates: { type: 'Coordinates', entity: Coordinates },
        countOnCheck: { type: 'int' },
        createdAt: { type: 'int' },
        createdBy: { type: 'UserLink', entity: UserLink },
        csiFeedback: { type: 'CsiFeedback[]', entity: CsiFeedback, default: [] },
        dateAdditionalCheck: { type: 'int' },
        dateAssignedExecutor: { type: 'int' },
        dateClose: { type: 'int' },
        dateComplete: { type: 'int' },
        dateControl: { type: 'int' },
        dateFeedback: { type: 'int' },
        dateOnAdditionalCheck: { type: 'int' },
        datePlannedCompletion: { type: 'int' },
        dateSpecialistArrival: { type: 'int' },
        dateTakeInWork: { type: 'int' },
        documentId: { type: 'string' },
        documentManagementLink: { type: 'string' },
        documentType: { type: 'string' },
        entrance: { type: 'EntranceLink', entity: EntranceLink },
        executor: { type: 'UserLink', entity: UserLink },
        files: { type: 'Doc[]', entity: Doc, default: () => [] },
        floor: { type: 'int' },
        hasChatMessages: { type: 'bool' },
        hasUnansweredChatMessages: { type: 'bool' },
        id: { type: 'string' },
        images: { type: 'Photo[]', entity: Photo, default: [] },
        imagesDone: { type: 'Photo[]', entity: Photo, default: [] },
        imagesRejected: { type: 'Photo[]', entity: Photo, default: [] },
        inspectionInfo: { type: 'TicketInspectionInfo[]', entity: TicketInspectionInfo, default: [] },
        isChatOpen: { type: 'bool' },
        isCritical: { type: 'bool' },
        isFromWorker: { type: 'bool' },
        isMass: { type: 'bool' },
        loadToBitrix: { type: 'bool' },
        manager: { type: 'UserLink', entity: UserLink },
        materials: { type: 'string' },
        number: { type: 'int' },
        overdueDays: { type: 'int' },
        parentTicket: { type: 'TicketLink', entity: TicketLink },
        penaltyAmount: { type: 'float' },
        placement: { type: 'PlacementLink', entity: PlacementLink },
        plannedAmount: { type: 'float' },
        premise: { type: 'PremiseLink', entity: PremiseLink },
        residentialComplex: { type: 'ResidentialComplex', entity: ResidentialComplex },
        room: { type: 'RoomLink', entity: RoomLink },
        responsible: { type: 'UserLink', entity: UserLink },
        scAcceptanceInfo: { type: 'TicketSCAcceptanceInfo', entity: TicketSCAcceptanceInfo },
        sector: { type: 'SectorLink', entity: SectorLink },
        service: { type: 'Service', entity: Service },
        text: { type: 'string' },
        ticketBitrixLink: { type: 'string' }, // string URL
        ticketByRefuser: { type: 'bool' },
        ticketDepartment: { type: 'TicketDepartmentLink', entity: TicketDepartmentLink },
        ticketKind: { type: 'TicketKindLink', entity: TicketKindLink },
        ticketSource: { type: 'TicketSource', entity: TicketSource },
        ticketStage: { type: 'TicketStageLink', entity: TicketStageLink },
        ticketType: { type: 'TicketType', entity: TicketType },
        title: { type: 'string' },
        unreadMessagesCount: { type: 'int' },
    },
}

class Ticket extends Entity {
    get schema() {
        return TicketSchema
    }

    get dataMap() {
        return {
            actual_amount: 'actualAmount',
            additional_reviewers: 'additionalReviewers',
            amount_required: 'amountRequired',
            applicant_chat: 'applicantChat',
            applicant_feedbacks: 'applicantFeedbacks',
            chat_responsible: 'chatResponsible',
            checklist_data: 'checklistData',
            child_tickets: 'childTickets',
            commission_rate: 'commissionRate',
            commission_sum: 'commissionSum',
            count_on_check: 'countOnCheck',
            created_at: 'createdAt',
            created_by: 'createdBy',
            csi_feedback: 'csiFeedback',
            date_additional_check: 'dateAdditionalCheck',
            date_assigned_executor: 'dateAssignedExecutor',
            date_close: 'dateClose',
            date_complete: 'dateComplete',
            date_control: 'dateControl',
            date_feedback: 'dateFeedback',
            date_on_additional_check: 'dateOnAdditionalCheck',
            date_planned_completion: 'datePlannedCompletion',
            date_specialist_arrival: 'dateSpecialistArrival',
            date_take_in_work: 'dateTakeInWork',
            document_id: 'documentId',
            document_management_link: 'documentManagementLink',
            document_type: 'documentType',
            has_chat_messages: 'hasChatMessages',
            has_unanswered_chat_messages: 'hasUnansweredChatMessages',
            images_done: 'imagesDone',
            images_rejected: 'imagesRejected',
            inspection_info: 'inspectionInfo',
            is_chat_open: 'isChatOpen',
            is_critical: 'isCritical',
            is_from_worker: 'isFromWorker',
            is_mass: 'isMass',
            load_to_bitrix: 'loadToBitrix',
            overdue_days: 'overdueDays',
            parent_ticket: 'parentTicket',
            penalty_amount: 'penaltyAmount',
            planned_amount: 'plannedAmount',
            residential_complex: 'residentialComplex',
            sc_acceptance_info: 'scAcceptanceInfo',
            bitrix_ticket_link: 'ticketBitrixLink',
            ticket_by_refuser: 'ticketByRefuser',
            ticket_department: 'ticketDepartment',
            ticket_kind: 'ticketKind',
            ticket_source: 'ticketSource',
            ticket_stage: 'ticketStage',
            ticket_type: 'ticketType',
            unread_messages_count: 'unreadMessagesCount',
        }
    }

    get canOpenChat() {
        return true
    }

    get canWriteToChat() {
        return this.isChatOpen
    }

    get typingCaption() {
        const typing = []

        if (this.ticketDepartment) {
            typing.push(this.ticketDepartment.name)
        }

        if (this.ticketKind) {
            typing.push(this.ticketKind.name)
        }

        if (this.ticketType) {
            typing.push(this.ticketType.name)
        }

        return typing.join(' / ')
    }

    static fieldLabels = {
        additionalReviewers: 'Дополнительные проверяющие',
        applicant: 'Заявитель',
        applicantChat: 'Чат с заявителем',
        auditors: 'Наблюдатели',
        building: 'Дом',
        chatEnabled: 'Доступность чата',
        chatResponsible: 'Ответственный за чат',
        checklistData: 'Чеклист',
        client: 'Клиент',
        answers: 'Ответы',
        datePoll: 'Дата',
        commissionRate: 'Процент комиссии',
        commissionSum: 'Сумма комиссии',
        coordinates: 'Координаты',
        countOnCheck: 'Количество проверок',
        createdAt: 'Дата создания',
        createdBy: 'Создавший заявку',
        dateAdditionalCheck: 'Дата и время проверки дополнительным проверяющим',
        dateAssignedExecutor: 'Дата назначения исполнителя',
        dateClose: 'Дата закрытия',
        dateComplete: 'Дата выполнения',
        dateControl: 'Дата контроля',
        dateFeedback: 'Дата получения оценки',
        dateOnAdditionalCheck: ' Дата и время перехода на доп проверку',
        datePlannedCompletion: 'Дата планируемого выполнения',
        dateSpecialistArrival: 'Дата прихода специалиста',
        dateTakeInWork: 'Дата взятия в работу',
        documentLink: 'Ссылка на документ',
        documentManagementLink: 'Ссылка на обращение в 1С',
        entrance: 'Подъезд',
        executor: 'Исполнитель',
        files: 'Документы',
        floor: 'Этаж',
        hasChatMessages: 'Сообщения в чате',
        hasUnansweredChatMessages: 'Есть неотвеченные сообщения',
        id: 'ID',
        images: 'Фотографии',
        imagesDone: 'Фото (выполнено)',
        imagesRejected: 'Фото (отклонено)',
        inspectionInfo: 'Информация об обходе',
        isCritical: 'Критичная',
        isFromWorker: 'Заявка от сотрудника',
        isMass: 'Массовая',
        manager: 'Руководитель',
        materials: 'Материалы',
        number: 'Номер',
        overdueDays: 'Количество дней просрочки',
        parentTicket: 'Родительская заявка',
        penaltyAmount: 'Сумма штрафа подрядчика, руб',
        placement: 'Место',
        premise: 'Помещение',
        residentialComplex: 'ЖК',
        responsible: 'Ответственный',
        room: 'Комната',
        scAcceptanceInfo: 'Информация о приемке',
        sector: 'Сектор',
        service: 'Сервис',
        csiFeedback: 'Отзыв',
        text: 'Текст',
        ticketBitrixLink: 'Связанная заявка в Bitrix',
        ticketByRefuser: 'Заявка от отказника',
        ticketDepartment: 'Отдел',
        ticketKind: 'Вид',
        ticketSource: 'Источник',
        ticketStage: 'Стадия',
        ticketType: 'Тип',
        title: 'Заголовок',
        unreadMessagesCount: 'Кол-во непрочитанных сообщений',
    }

    toServer() {
        return {
            applicant: this.applicant?.id,
            building: this.building?.id,
            date_control: this.dateControl,
            document_id: this.documentId,
            document_management_link: this.documentManagementLink,
            document_type: this.documentType,
            entrance: this.entrance?.id,
            files: _map(this.files, (file) => file.id),
            floor: this.floor,
            images: _map(this.images, (image) => image.id),
            is_from_worker: this.isFromWorker,
            parent_ticket: this.parentTicket?.id,
            premise: this.premise?.id,
            residential_complex: this.residentialComplex?.id || null,
            responsible: this.responsible?.id,
            sector: this.sector?.id,
            text: this.text,
            ticket_department: this.ticketDepartment?.id,
            ticket_kind: this.ticketKind?.id,
            ticket_type: this.ticketType?.id,
            title: this.title,
        }
    }
}

export default Ticket
