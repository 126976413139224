<template>
    <v-navigation-drawer
        v-model="drawerModel"
        app
        color="secondary"
        :expand-on-hover="drawerMiniVariant"
        :mini-variant.sync="drawerMiniVariant"
        :temporary="!isDesktop"
        :width="330"
        @mouseenter.native="onEnterMouseOnDrawer"
        @mouseleave.native="onLeaveMouseOnDrawer"
    >
        <v-list nav dark>
            <v-list-item :class="drawerMiniVariant && isLeftMouseOnDrawer && 'px-0'">
                <v-list-item-avatar>
                    SCW
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        Service Company Web
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider />

        <v-list nav dark>
            <v-list-item
                :class="drawerMiniVariant && isLeftMouseOnDrawer && 'px-0'"
                link
                :to="{ name: 'Profile', params: { userId } }"
                two-line
            >
                <v-list-item-avatar>
                    <CAvatar :value="currentWorkerPhoto || null" size="36" />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                        {{ currentWorker ? currentWorker.user?.fullName : '-' }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ currentWorker?.workerPosition ? currentWorker?.workerPosition?.name : '-' }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider />

        <v-list nav dark>
            <template v-for="item in menu">
                <template v-if="item.children && item.children.length">
                    <v-list-group :key="item.code" :value="item.isOpen" color="white">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-badge
                                    :color="item.chipColor || 'red'"
                                    :content="item.count > 99 ? '99+' : item.count"
                                    offset-x="12"
                                    offset-y="12"
                                    :value="item.count ? item.count : 0"
                                >
                                    <v-icon
                                        v-if="item.icon"
                                        size="24"
                                        v-text="`$${item.icon}`"
                                    />
                                </v-badge>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    <router-link v-if="item.route" :to="item.route" class="text-decoration-none white--text">
                                        {{ item.name }}
                                    </router-link>
                                    <span v-else> {{ item.name }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <CMenuItem
                            v-for="child in item.children"
                            :key="child.code"
                            :action-text="child.count"
                            :avatar="drawerMiniVariant && isLeftMouseOnDrawer && getAvatar(child)"
                            :action-chip="child.chip"
                            :target="child.target"
                            :chip-color="child.chipColor"
                            :class="drawerMiniVariant && isLeftMouseOnDrawer && 'px-0'"
                            :exact="$route.name === 'Tickets'"
                            :icon="child.icon"
                            :route="child.route"
                            :on-click="child.onClick"
                            :title="child.name"
                            type="groupItem"
                        />
                    </v-list-group>
                </template>
                <template v-else>
                    <CMenuItem
                        :key="item.code"
                        :action-text="item.count"
                        :chip-color="item.chipColor"
                        :icon="item.icon"
                        :icon-chip="item.iconChip"
                        :route="item.route"
                        :title="item.name"
                    />
                </template>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    // MD and Down - Hide
    // LG and Up - Show

    import { API } from '_api'
    import {
        cloneDeep as _cloneDeep,
        compact as _compact,
        filter as _filter,
        forEach as _forEach,
        map as _map,
    } from 'lodash'
    import { mapState, mapGetters } from 'vuex'

    import { host } from '_api/v1/base/behemoth/config'

    import UserCounters from '_entities/user/UserCounters'

    import { PERMISSION_MODULES, PERMISSIONS } from '_features/permissions/constants'

    import CMenuItem from '_common/components/NavigationDrawer/CMenuItem'
    import CAvatar from '_common/components/CAvatar'

    export default {
        name: 'CNavigationDrawer',

        components: {
            CMenuItem,
            CAvatar,
        },

        data: () => ({
            approvalsCount: 0,
            unrecallCallsCount: 0,
            isEnteredMouseOnDrawer: false,
            isFetchingNotifications: false,
            isFetchingUnrecallCallsCount: false,
            isLeftMouseOnDrawer: true,
            //
            notificationIntervalId: null,
            unrecallCallsCountInterval: null,
        }),

        computed: {
            ...mapState('global', {
                navigationDrawer: (state) => state.navigationDrawer,
            }),

            ...mapState('auth', {
                userId: (state) => state.userId,
                currentWorker: (state) => state.currentWorker,
            }),

            ...mapGetters({
                isShownNavigationDrawer: 'global/getShownNavigationDrawer',
            }),

            unrecallCallsQueue() {
                return this.$store.state.callQueue.callQueue
            },

            isAppActive() {
                return this.$store.state.global.isAppActive
            },

            userCounters() {
                return this.$store.state.userCounters.counters
            },

            isDesktop() {
                return this.$vuetify.breakpoint.lgAndUp
            },

            drawerModel: {
                get() {
                    if (this.isDesktop) {
                        return true
                    } else {
                        return this.navigationDrawer?.isShown
                    }
                },
                set(value) {
                    this.toggleNavigationDrawer(value)
                },
            },

            drawerMiniVariant() {
                if (this.isDesktop) {
                    return !this.navigationDrawer?.isShown
                } else {
                    return false
                }
            },

            ticketsFilter() {
                return this.$store.getters['filter/getFilterByCode']('tickets')
            },

            ticketsFilterPresets() {
                const items = []

                const filter = _cloneDeep(this.ticketsFilter)

                _forEach(filter?.presets, (preset) => {
                    let count
                    let name = preset.name

                    switch (preset.code) {
                        case 'assigned':
                            count = this.userCounters.ticketsExecutorAppointedUnread
                            name += ` (${this.userCounters.ticketsExecutorAppointed})`
                            break

                        case 'work':
                            count = this.userCounters.ticketsInProgressUnread
                            name += ` (${this.userCounters.ticketsInProgress})`
                            break

                        case 'new':
                            count = this.userCounters.ticketsExecutorAppointedUnread
                            name += ` (${this.userCounters.ticketsExecutorAppointed})`
                            break
                    }

                    items.push({
                        code: preset.code,
                        count,
                        isAllowed: this.$hasPermission([
                            PERMISSIONS.CAN_VIEW_TICKET,
                        ]),
                        name,
                        onClick: () => {
                            this.$store.dispatch('filter/applyFilterPreset', { filter, filterPreset: preset })
                            this.$router.push({ name: 'Tickets' })
                        },
                    })
                })

                return items
            },

            menu() {
                const menu = []

                // Dashboard --------------------------------------------------------

                menu.push({
                    code: 'dashboard',
                    icon: 'newspaper',
                    isAllowed: true,
                    name: 'Рабочий стол',
                    route: {
                        name: 'Dashboard',
                    },
                })

                // Tickets -----------------------------------------------------

                menu.push({
                    code: 'tickets',
                    count: this.userCounters.ticketsExecutorAppointedUnread + this.userCounters.ticketsExecutorAppointedUnread,
                    icon: 'clipboardList',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.TICKET,
                    ]),
                    isOpen: this.$route.matched.some((o) => o.name === 'TicketsNavigator'),
                    name: `Заявки (${this.userCounters.ticketsExecutorAppointed + this.userCounters.ticketsNeedExecutor + this.userCounters.ticketsInProgress})`,
                    children: [
                        {
                            code: 'ticketsAll',
                            isAllowed: true,
                            name: 'Все',
                            onClick: () => {
                                const filter = _cloneDeep(this.ticketsFilter)

                                this.$store.dispatch('filter/resetFilter', { filter })
                            },
                            route: {
                                name: 'Tickets',
                            },
                        },
                        {
                            code: 'overdue',
                            isAllowed: true,
                            name: `Просроченные (${this.userCounters.ticketsOverdueCount})`,
                            route: {
                                name: 'TicketOverdue',
                            },
                        },
                        {
                            code: 'almostOverdue',
                            isAllowed: true,
                            name: `Остался 1 день (${this.userCounters.ticketsAlmostOverdueCount})`,
                            route: {
                                name: 'TicketAlmostOverdue',
                            },
                        },
                        {
                            code: 'onNew',
                            // TODO: Скрыто до момента, пока не будет вычисляться корректно
                            // count: this.userCounters.ticketsExecutorAppointedUnread,
                            isAllowed: true,
                            name: `Новые (${this.userCounters.ticketsExecutorAppointed})`,
                            route: {
                                name: 'TicketOnNew',
                            },
                        },
                        {
                            code: 'createdByMe',
                            isAllowed: true,
                            name: 'Я заявитель',
                            route: {
                                name: 'TicketCreatedByMe',
                            },
                        },
                        {
                            code: 'need-executor',
                            count: this.userCounters.ticketsNeedExecutorUnread,
                            isAllowed: true,
                            name: `Назначить (${this.userCounters.ticketsNeedExecutor})`,
                            route: {
                                name: 'TicketOnNeedExecutor',
                            },
                        },
                        {
                            code: 'onContract',
                            isAllowed: true,
                            name: 'Договор',
                            route: {
                                name: 'TicketOnContract',
                            },
                        },
                        {
                            code: 'onInWork',
                            count: this.userCounters.ticketsInProgressUnread,
                            isAllowed: true,
                            name: `Мои в работе (${this.userCounters.ticketsInProgress})`,
                            route: {
                                name: 'TicketOnInWork',
                            },
                        },
                        ...this.ticketsFilterPresets,
                        {
                            code: 'auditor',
                            isAllowed: true,
                            name: `Наблюдаю  (${this.userCounters.ticketsAuditCount})`,
                            route: {
                                name: 'TicketAuditor',
                            },
                        },
                        {
                            code: 'onCheck',
                            count: this.userCounters.ticketsOnCheckUnread + this.userCounters.ticketsOnAdditionalCheckUnread,
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_TICKET_ON_CHECK,
                            ]),
                            name: `На проверке (${this.userCounters.ticketsOnCheck}/${this.userCounters.ticketsOnAdditionalCheck})`,
                            route: {
                                name: 'TicketOnCheck',
                            },
                        },
                        {
                            code: 'approval',
                            count: this.approvalsCount,
                            isAllowed: true,
                            name: 'Согласование',
                            route: {
                                name: 'TicketApproval',
                            },
                        },
                        {
                            code: 'ticketsNotifications',
                            isAllowed: false,
                            name: 'Уведомления',
                        },
                    ],
                })

                // Tickets setup -------------------------------------------------

                menu.push({
                    code: 'tickets_setup',
                    icon: 'cog',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_TICKET_SETTINGS,
                    ]),
                    name: 'Настройки заявки',
                    children: [
                        {
                            code: 'ticketsRouter',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_ROUTER_TICKET_ROUTE,
                            ]),
                            name: 'Маршрутизатор',
                            route: {
                                name: 'TicketRoutes',
                            },
                        },
                        {
                            code: 'typing',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_TICKET_DEPARTMENT,
                                PERMISSIONS.CAN_VIEW_TICKET_KIND,
                                PERMISSIONS.CAN_VIEW_TICKET_TYPE,
                            ]),
                            name: 'Типизация',
                            route: {
                                name: 'TicketTyping',
                            },
                        },
                        {
                            code: 'additionalCheck',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_TICKET,
                            ]),
                            name: 'Установка проверки',
                            route: {
                                name: 'TicketAdditionalCheck',
                            },
                        },
                        {
                            code: 'chatTimeLimits',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_CHAT_TIME_SETTING,
                            ]),
                            name: 'Установка сроков ответа',
                            route: {
                                name: 'ChatTimeLimits',
                            },
                        },
                    ],
                })

                // Комментарии ----------------------------------------------------

                menu.push({
                    chipColor: 'primary',
                    code: 'solutions',
                    icon: 'peopleArrows',
                    count: this.userCounters.needSolutionCount,
                    isAllowed: this.$hasPermission([PERMISSIONS.CAN_VIEW_NEED_ANSWER_SECTION]),
                    name: 'Комментарии',
                    children: [
                        {
                            code: 'ticketSolutions',
                            isAllowed: true,
                            name: 'Комментарии',
                            route: {
                                name: 'TicketSolutions',
                            },
                        },
                        {
                            code: 'myTicketSolutions',
                            isAllowed: true,
                            name: 'Мои запросы',
                            route: {
                                name: 'MyTicketSolutions',
                            },
                        },
                    ],
                })

                // Отчеты в МПК ----------------------------------------------

                menu.push({
                    code: 'mobile_analytics',
                    icon: 'mobileScreen',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_SCREPORT,
                    ]),
                    name: 'Отчеты в МПК',
                    children: [
                        {
                            code: 'monthReports',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_SCREPORT,
                            ]),
                            name: 'Отчеты',
                            route: {
                                name: 'MonthReports',
                            },
                        },
                        {
                            code: 'photoReports',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_PHOTOREPORT,
                            ]),
                            name: 'Фотографии',
                            route: {
                                name: 'PhotoReports',
                            },
                        },
                    ],
                })

                // Надо ответить ----------------------------------------------------

                menu.push({
                    chipColor: 'primary',
                    code: 'chats',
                    icon: 'comments',
                    iconChip: this.userCounters.needAnswerSectionCount,
                    isAllowed: this.$hasPermission([PERMISSIONS.CAN_VIEW_NEED_ANSWER_SECTION]),
                    name: 'Надо ответить',
                    route: {
                        name: 'Chats',
                    },
                })

                // Residential fund --------------------------------------------

                menu.push({
                    code: 'residential_fund',
                    icon: 'houseUser',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.LOCATION,
                    ]),
                    name: 'Жилой фонд',
                    children: [
                        // {
                        //     code: 'contacts',
                        //     name: 'Контакты',
                        // },
                        {
                            code: 'Cities',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LOCATION_CITY,
                            ]),
                            name: 'Города',
                            route: {
                                name: 'Cities',
                            },
                        },
                        {
                            code: 'residentialComplex',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LOCATION_RESIDENTIAL_COMPLEX,
                            ]),
                            name: 'ЖК',
                            route: {
                                name: 'ResidentialComplexes',
                            },
                        },
                        {
                            code: 'buildings',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LOCATION_BUILDING,
                            ]),
                            name: 'Дома',
                            route: {
                                name: 'Buildings',
                            },
                        },
                        {
                            code: 'personalAccounts',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LOCATION_PERSONAL_ACCOUNT,
                            ]),
                            name: 'Лицевые счета',
                            route: {
                                name: 'PersonalAccounts',
                            },
                        },
                        // {
                        //     code: 'acts',
                        //     name: 'Акты',
                        // },
                        // {
                        //     code: 'cars',
                        //     name: 'Автомобили',
                        // },
                    ],
                })

                // Knowledge base ----------------------------------------------

                menu.push({
                    code: 'knowledgeBase',
                    icon: 'book',
                    isAllowed: true,
                    name: 'База знаний',
                    route: {
                        name: 'KnowledgeBase',
                    },
                })

                // Indications ----------------------------------------------

                menu.push({
                    code: 'indications',
                    icon: 'plug',
                    isAllowed: true,
                    name: 'Передача показаний',
                    children: [
                        {
                            code: 'MyCountryCompany',
                            isAllowed: true,
                            name: 'УК МОЯ СТРАНА',
                            route: {
                                name: 'MyCountryCompany',
                            },
                        },
                        {
                            code: 'HorizonCompany',
                            isAllowed: true,
                            name: 'УК ГОРИЗОНТ',
                            route: {
                                name: 'HorizonCompany',
                            },
                        },
                    ],
                })

                // Inventory ---------------------------------------------------

                // menu.push({
                //     code: 'inventory',
                //     name: 'ТМЦ',
                //     icon: 'boxesAlt',
                //     children: [
                //         {
                //             code: 'contacts',
                //             name: 'Склад',
                //         },
                //         {
                //             code: 'receivingInventory',
                //             name: 'Получение ТМЦ',
                //         },
                //         {
                //             code: 'purchaseInventory',
                //             name: 'Закупка ТМЦ',
                //         },
                //         {
                //             code: 'purchaseServices',
                //             name: 'Закупка услуг',
                //         },
                //     ],
                // })

                // Company -----------------------------------------------------

                menu.push({
                    code: 'company',
                    icon: 'building',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.DEPARTMENT,
                    ]),
                    name: 'Компания',
                    children: [
                        {
                            code: 'departments',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_DEPARTMENT,
                            ]),
                            name: 'Отделы',
                            route: {
                                name: 'Departments',
                            },
                        },
                        {
                            code: 'workers',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_DEPARTMENT_WORKER,
                            ]),
                            name: 'Сотрудники',
                            route: {
                                name: 'Workers',
                            },
                        },
                        {
                            code: 'absenceSchedule',
                            isAllowed: this.$hasPermission([PERMISSIONS.CAN_VIEW_ABSENT_SCHEDULE]),
                            name: 'График отсутствий',
                            route: {
                                name: 'AbsenceSchedule',
                            },
                        },
                        {
                            code: 'dutySchedule',
                            isAllowed: this.$hasPermission([PERMISSIONS.CAN_VIEW_DUTY_SCHEDULE]),
                            name: 'График дежурств',
                            route: {
                                name: 'DutySchedule',
                            },
                        },
                        {
                            code: 'queues',
                            isAllowed: true,
                            name: 'На линии',
                            route: {
                                name: 'Queues',
                            },
                        },
                        {
                            code: 'workShiftSchedule',
                            isAllowed: true,
                            name: 'Смены',
                            route: {
                                name: 'WorkShiftSchedule',
                            },
                        },
                        // {
                        //     code: 'phones',
                        //     name: 'Телефоны',
                        // },
                        // {
                        //     code: 'keys',
                        //     name: 'Ключи',
                        // },
                        // {
                        //     code: 'incomingEmail',
                        //     name: 'Входящие письма',
                        // },
                        // {
                        //     code: 'outgoingEmails',
                        //     name: 'Исходящие письма',
                        // },
                        // {
                        //     code: 'powerOfAttorney',
                        //     name: 'Доверенности',
                        // },
                    ],
                })

                // Users -----------------------------------------------------

                menu.push({
                    code: 'user',
                    icon: 'user',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_AUTH_GROUP,
                    ]),
                    name: 'Пользователи',
                    children: [
                        {
                            code: 'userGroups',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_AUTH_GROUP,
                            ]),
                            name: 'Группы пользователей',
                            route: {
                                name: 'UserGroups',
                            },
                        },
                    ],
                })

                // Reports -----------------------------------------------------

                // menu.push({
                //     code: 'reports',
                //     name: 'Отчёты',
                //     icon: 'chartPie',
                //     children: [
                //         {
                //             code: 'tickets',
                //             name: 'Заявки',
                //         },
                //         {
                //             code: 'inspections',
                //             name: 'Обходы',
                //         },
                //         {
                //             code: 'guarantee',
                //             name: 'Гарантия',
                //         },
                //     ],
                // })

                // Telephony --------------------------------------------

                menu.push({
                    chipColor: 'primary',
                    code: 'telephony',
                    count: this.unrecallCallsCount,
                    icon: 'phoneOffice',
                    isAllowed: this.hasModulePermission([PERMISSION_MODULES.CALL]),
                    name: 'Телефония',
                    children: [
                        {
                            blank: true,
                            code: 'accident',
                            isAllowed: true, // this.$hasPermission([PERMISSIONS.CAN_VIEW_PERSONAL_ACCOUNT]),
                            name: 'Панель телефонии',
                            route: {
                                name: 'PhoneSection',
                            },
                            target: '_blank',
                        },
                        {
                            code: 'accident',
                            isAllowed: true, // this.$hasPermission([PERMISSIONS.CAN_VIEW_PERSONAL_ACCOUNT]),
                            name: 'Автоинформатор',
                            route: {
                                name: 'Accident',
                            },
                        },
                        {
                            code: 'phoneCalls',
                            chip: this.unrecallCallsCount,
                            chipColor: 'primary',
                            isAllowed: true, // this.$hasPermission([PERMISSIONS.CAN_VIEW_PERSONAL_ACCOUNT]),
                            name: 'Звонки',
                            route: {
                                name: 'PhoneCalls',
                            },
                        },
                    ],
                })

                // Newsletters -------------------------------------------------

                menu.push({
                    code: 'newsletters',
                    icon: 'mailBulk',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.NEWSLETTER,
                    ]),
                    isOpen: this.$route.matched.some((o) => o.name === 'NewslettersNavigator'),
                    name: 'Рассылки',
                    children: [
                        // {
                        //     code: 'email',
                        //     name: 'Email',
                        // },
                        {
                            code: 'push',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_NEWSLETTER_PUSH_MESSAGE,
                            ]),
                            name: 'Push',
                            route: {
                                name: 'NewslettersPush',
                            },
                        },
                    ],
                })

                // User App ----------------------------------------------------

                menu.push({
                    code: 'user_app',
                    icon: 'pencilPaintbrush',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.CHAT_BOT,
                        PERMISSION_MODULES.DOCUMENT,
                        PERMISSION_MODULES.LOCATION,
                        PERMISSION_MODULES.NEWS,
                        PERMISSION_MODULES.SERVICE,
                    ]),
                    isOpen: this.$route.matched.some((o) => o.name === 'UserAppNavigator'),
                    name: 'Приложение жителя',
                    children: [
                        {
                            code: 'news',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_NEWS,
                            ]),
                            name: 'Новости',
                            route: {
                                name: 'UserAppNewsList',
                            },
                        },
                        {
                            code: 'quizzes',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_NEWS_QUIZ,
                            ]),
                            name: 'Опросы',
                            route: {
                                name: 'UserAppQuizzes',
                            },
                        },
                        {
                            code: 'services',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_SERVICE,
                            ]),
                            name: 'Сервисы',
                            route: {
                                name: 'UserAppServices',
                            },
                        },
                        {
                            code: 'cameras',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LOCATION_CAMERA,
                            ]),
                            name: 'Камеры',
                            route: {
                                name: 'UserAppCameras',
                            },
                        },
                        {
                            code: 'intercoms',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_INTERCOM,
                            ]),
                            name: 'Домофоны',
                            route: {
                                name: 'Intercoms',
                            },
                        },
                        {
                            code: 'stories',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_NEWS_STORY,
                            ]),
                            name: 'Stories',
                            route: {
                                name: 'UserAppStories',
                            },
                        },
                        {
                            code: 'legalDocuments',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LEGAL_DOCUMENT,
                            ]),
                            name: 'Правовые документы',
                            route: {
                                name: 'UserAppLegalDocuments',
                            },
                        },
                        {
                            code: 'chatBots',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_CHAT_BOT,
                            ]),
                            name: 'Чат-боты',
                            route: {
                                name: 'UserAppChatBots',
                            },
                        },
                    ],
                })

                // Компании ----------------------------------------------------

                menu.push({
                    code: 'companies',
                    icon: 'userTie',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_DEPARTMENT_COMPANY,
                    ]),
                    name: 'Компании',
                    route: {
                        name: 'Companies',
                    },
                })

                // Хранение ключей ---------------------------------------------

                menu.push({
                    code: 'storage',
                    icon: 'warehouse',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.STORAGE,
                    ]),
                    name: 'Хранение',
                    children: [
                        {
                            code: 'storageKeyIssuance',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_STORAGE_KEY_ISSUANCE,
                            ]),
                            name: 'Выдачи',
                            route: {
                                name: 'StorageKeyIssuance',
                            },
                        },
                        {
                            code: 'storageKey',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_STORAGE_KEY,
                            ]),
                            name: 'Ключи',
                            route: {
                                name: 'StorageKey',
                            },
                        },
                        {
                            code: 'storageProxy',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_STORAGE_PROXY,
                            ]),
                            name: 'Доверенности',
                            route: {
                                name: 'StorageProxy',
                            },
                        },
                    ],
                })

                // Документы ---------------------------------------------------

                menu.push({
                    code: 'documents',
                    icon: 'fileInvoice',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_DOCUMENTS,
                    ]),
                    name: 'Документы',
                    children: [
                        {
                            code: 'premiseDocuments',
                            isAllowed: true,
                            name: 'Документы по квартире',
                            route: {
                                name: 'PremiseDocuments',
                            },
                        },
                        {
                            code: 'appealDocuments',
                            isAllowed: true,
                            name: 'Обращения',
                            route: {
                                name: 'AppealDocuments',
                            },
                        },
                        {
                            code: 'incomingDocuments',
                            isAllowed: true,
                            name: 'Входящие',
                            route: {
                                name: 'IncomingDocuments',
                            },
                        },
                        {
                            code: 'outgoingDocuments',
                            isAllowed: true,
                            name: 'Исходящие',
                            route: {
                                name: 'OutgoingDocuments',
                            },
                        },
                        {
                            code: 'commercialAppealDocuments',
                            isAllowed: true,
                            name: 'Коммерческие обращения',
                            route: {
                                name: 'CommercialAppealDocuments',
                            },
                        },
                        {
                            code: 'criticalCommentActs',
                            isAllowed: true,
                            name: 'Акты по крит.',
                            route: {
                                name: 'CriticalCommentActs',
                            },
                        },
                    ],
                    route: {
                        name: 'PremiseDocuments',
                    },
                })

                // Отчеты ------------------------------------------------------

                menu.push({
                    code: 'analytics',
                    icon: 'analytics',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.ANALYTICS,
                    ]),
                    name: 'Отчеты',
                    children: [
                        {
                            code: 'serviceCompany',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_ANALYTICS,
                            ]),
                            name: 'Управляющая компания',
                            route: {
                                name: 'ServiceCompany',
                            },
                        },
                    ],
                })

                // Calendar -------------------------------------------------

                menu.push({
                    code: 'calendar',
                    icon: 'calendarDay',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_SLOT,
                    ]),
                    name: 'Календарь',
                    route: {
                        name: 'Calendar',
                    },
                })

                // Настройки системы -------------------------------------------------
                menu.push({
                    code: 'settings',
                    icon: 'cog',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_MAINTENANCE_WORK_EVENT,
                    ]),
                    name: 'Настройки',
                    children: [
                        {
                            code: 'MaintenanceWork',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_MAINTENANCE_WORK_EVENT,
                            ]),
                            name: 'Техработы',
                            route: {
                                name: 'maintenanceWork',
                            },
                        },
                    ],
                })

                // Development -------------------------------------------------
                menu.push({
                    code: 'experimentalFeatures',
                    icon: 'code',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_EXPERIMENTAL_FEATURES,
                    ]) || process.env.VUE_APP_ENV === 'development',
                    name: 'Тестирование',
                    children: [
                        {
                            code: 'NotificationCenterTest',
                            isAllowed: true,
                            name: 'Центр уведомлений',
                            route: {
                                name: 'notificationCenterTest',
                            },
                        },
                        {
                            code: 'FilterTest',
                            isAllowed: true,
                            name: 'Filter',
                            route: {
                                name: 'FilterTest',
                            },
                        },
                        {
                            code: 'ScrollTableTestScreen',
                            isAllowed: true,
                            name: 'Скролл таблицы',
                            route: {
                                name: 'ScrollTableTestScreen',
                            },
                        },
                        {
                            code: 'UITestScreen',
                            isAllowed: true,
                            name: 'UI тест',
                            route: {
                                name: 'UITest',
                            },
                        },
                        {
                            code: 'PhoneTestScreen',
                            isAllowed: true,
                            name: 'Тест телефонии',
                            route: {
                                name: 'PhoneTest',
                            },
                        },
                    ],
                })

                const resolvedMenu = _map(menu, (menuItem1) => {
                    if (menuItem1.isAllowed) {
                        const children = _filter(menuItem1.children, (menuItem2) => {
                            return Boolean(menuItem2.isAllowed)
                        })

                        return { ...menuItem1, children }
                    }

                    return null
                })

                return _compact(resolvedMenu)
            },

            currentWorkerPhoto() {
                const photo = this.currentWorker?.user?.photo
                if (photo) {
                    // if photo url is not absolute
                    // eslint-disable-next-line prefer-regex-literals
                    const AbsoluteUrlRgExp = new RegExp('^(?:[a-z]+:)?//', 'i')
                    if (!AbsoluteUrlRgExp.test(photo.getSmall().url)) {
                        photo.getSmall().url = `${host}/${photo.getSmall().url}`
                    }
                }
                return photo
            },

            canViewRecallSection() {
                return this.$hasPermission([PERMISSIONS.CAN_VIEW_RECALL_SECTION])
            },
        },

        watch: {
            isDesktop: {
                handler(isDesktop) {
                    this.toggleNavigationDrawer(isDesktop)
                },
                immediate: true,
            },

            unrecallCallsQueue: {
                handler() {
                    this.fetchUnrecallCallsCount()
                },
                deep: true,
            },

            isAppActive: {
                handler(isAppActive) {
                    if (isAppActive) {
                        this.refreshCounters()
                    }
                },
            },
        },

        mounted() {
            this.startFetchNotificationsInterval()

            if (this.canViewRecallSection) {
                this.startUnrecallCallCounterInterval()
            }
        },

        methods: {
            getTicketStageByCode(stageCode) {
                return this.$store.getters['ticketStages/getTicketStageByCode'](stageCode)
            },

            startFetchNotificationsInterval() {
                if (this.notificationIntervalId) {
                    window.clearInterval(this.notificationIntervalId)
                    this.notificationIntervalId = null
                }

                this.fetchNotifications()

                this.notificationIntervalId = window.setInterval(() => {
                    this.fetchNotifications()
                }, 1000 * 60 * 10) // Обновление каждые 10 минут в дополнение к websocket событиям
            },

            async startUnrecallCallCounterInterval() {
                if (this.unrecallCallsCountInterval) {
                    window.clearInterval(this.unrecallCallsCountInterval)
                    this.unrecallCallsCountInterval = null
                }

                // Рассинхрон на 1 секунду с запросом каунтеров заявок для избежания пиков нагрузки
                await new Promise((resolve) => setTimeout(() => resolve(), 1000))

                this.fetchUnrecallCallsCount()

                this.unrecallCallsCountInterval = window.setInterval(() => {
                    this.fetchUnrecallCallsCount()
                }, 1000 * 60) // Обновление каждую минуту
            },

            async fetchNotifications() {
                if (this.isFetchingNotifications || !this.isAppActive) {
                    return
                }

                this.isFetchingNotifications = true

                try {
                    const response = await API.v1.services.notification.getUnreadMessagesCount()

                    const { result } = response

                    this.$store.commit('userCounters/updateCounters', new UserCounters(result))

                    // Получение количества согласований
                    const approvalParams = {
                        responsible__in: this.currentWorker?.id,
                        status__in: 'open',
                    }
                    const approvalResponse = await API.v1.services.approval.getApprovals(approvalParams)
                    if (approvalResponse) {
                        this.approvalsCount = approvalResponse.count || 0
                    }
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить данные счетчиков', e)
                    this.$logger.logException(e)
                }

                this.isFetchingNotifications = false
            },

            async fetchUnrecallCallsCount() {
                if (this.isFetchingUnrecallCallsCount || !this.isAppActive) {
                    return
                }

                this.isFetchingUnrecallCallsCount = true
                this.unrecallCallsCount = 0

                try {
                    if (this.unrecallCallsQueue) {
                        const unrecallCallsFilter = this.$store.getters['filter/getFilterByCode']('unrecallPhoneCalls')
                        const params = {}

                        params.queue_number = this.unrecallCallsQueue?.number

                        _forEach(unrecallCallsFilter?.filterValues, (filterParameter) => {
                            params[filterParameter.fieldCode] = filterParameter.value
                        })

                        const response = await API.v1.services.call.getUnrecallCallsCount(params)

                        this.unrecallCallsCount = response?.qty_recall_group || 0
                    }
                } catch (e) {
                    this.$root.$pushRequestNotifications('Не удалось получить счетчика неотвеченных звонков', e)
                    this.$logger.logException(e)
                }

                this.isFetchingUnrecallCallsCount = false
            },

            async refreshCounters() {
                await this.fetchNotifications()

                if (this.canViewRecallSection) {
                    // Рассинхрон на 1 секунду с запросом каунтеров заявок для избежания пиков нагрузки
                    await new Promise((resolve) => setTimeout(() => resolve(), 1000))

                    await this.fetchUnrecallCallsCount()
                }
            },

            // TODO: remove simulate
            getRandomNotificationsCount(max) {
                return Math.floor(Math.random() * Math.floor(max))
            },

            toggleNavigationDrawer() {
                const navigationDrawer = this.navigationDrawer

                this.$store.commit('global/setNavigationDrawer', {
                    ...navigationDrawer,
                    isShown: this.isShownNavigationDrawer,
                })
            },

            onEnterMouseOnDrawer() {
                this.isEnteredMouseOnDrawer = true
                this.isLeftMouseOnDrawer = false
            },

            onLeaveMouseOnDrawer() {
                this.isEnteredMouseOnDrawer = false
                this.isLeftMouseOnDrawer = true
            },

            getAvatar(child) {
                let name = child.name

                name = name.replace(/[^A-Za-zА-Яа-я]/g, '')

                return name.substring(0, 2).toUpperCase()
            },

            hasModulePermission(checkPermissionModules) {
                const per = this.$store.getters['permissions/hasModulePermission'](checkPermissionModules)
                if (!per) {
                    // eslint-disable-next-line no-console
                    console.log('PERMISSION MODULE ', checkPermissionModules, ': ', per)
                }
                return this.$store.getters['permissions/hasModulePermission'](checkPermissionModules)
            },
        },
    }
</script>
