import { request } from '_api/v1/base/behemoth/client'

export const getSentryDSN = async (params) => {
    return await request('POST', 'sync/get_sentry_dsn/', params)
}

export const getDocumentManagementTypes = async (params) => {
    return await request('GET', 'sync/document_management_type/', params)
}

export const documentManagementCreateProcess = async (ticketId, params) => {
    return await request('POST', `sync/document_management/create_process/${ticketId}/`, params)
}
