import { request } from '_api/v1/base/behemoth/client'
import Coordinates from '_entities/coordinates/Coordinates'

export const getBuildings = async (options) => {
    const params = {}
    const filters = {}

    for (const field in options) {
        let value = options[field]

        if (['page', 'page_size', 'ordering'].indexOf(field) !== -1) {
            params[field] = value
        } else {
            if (field.indexOf('__in') > 0 && typeof value === 'string') {
                value = value.split(',')

                if (!Array.isArray(value)) {
                    value = [value]
                }
            }

            filters[field] = value
        }
    }

    const response = await request('POST', 'post/buildings/', filters, { params })

    if (response?.coordinates && typeof response?.coordinates !== 'object') {
        const coordinates = response.coordinates?.split(':')

        response.coordinates = {
            lat: coordinates[0],
            lon: coordinates[1],
        }
    } else if (!response.coordinates) {
        response.coordinates = new Coordinates()
    }
    return response
}

export const getBuildingById = async (buildingId) => {
    const response = await request('GET', `building/${buildingId}/`)

    if (response?.coordinates && typeof response?.coordinates !== 'object') {
        const coordinates = response.coordinates?.split(':')

        response.coordinates = {
            lat: coordinates[0],
            lon: coordinates[1],
        }
    } else if (!response.coordinates) {
        response.coordinates = new Coordinates()
    }
    return response
}

export const updateBuilding = async ({ buildingId, data }) => {
    return await request('PUT', `building/${buildingId}/`, data)
}
