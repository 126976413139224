import { filter as _filter, map as _map } from 'lodash'

import moment from 'moment'

import Entity from '_entities/base/EntityNew'

import Address from '_entities/address/Address'
import BuildingType from '_entities/building/BuildingType'
import CityLink from '_entities/city/CityLink'
import Company from '_entities/company/Company'
import Coordinates from '_entities/coordinates/Coordinates'
import Doc from '_entities/media/Doc'
import MeterType from '_entities/meter/MeterType'
import ResidentialComplex from '_entities/residentialComplex/ResidentialComplex'
import SectorLink from '_entities/sector/SectorLink'
import ServiceLink from '_entities/service/ServiceLink'
import Photo from '_entities/media/Photo'
import User from '_entities/user/User'

export const BuildingSchema = {
    name: 'Building',
    primaryKey: 'id',
    properties: {
        address: { type: 'Address', entity: Address },
        buildingType: { type: 'BuildingType', entity: BuildingType },
        city: { type: 'CityLink', entity: CityLink },
        coordinates: { type: 'Сoordinates', entity: Coordinates },
        companies: { type: 'Company[]', entity: Company, defaul: [] },
        constructionDate: { type: 'int' },
        constructionProgress: { type: 'int' },
        constructionStartDate: { type: 'int' },
        dateFinishAcceptance: { type: 'int' },
        dateStartAcceptance: { type: 'int' },
        deletedAt: { type: 'int' },
        deletedBy: { type: 'User', entity: User },
        finishDate: { type: 'int' },
        gp: { type: 'string' },
        id: { type: 'string' }, // UUID
        isActive: { type: 'bool', default: false },
        image: { type: 'Photo', entity: Photo },
        internalName: { type: 'string' },
        isAllowedManualEditionOwners: { type: 'bool', default: false },
        isAvailableForAccountPayment: { type: 'bool' },
        isDone: { type: 'bool' },
        keyService: { type: 'ServiceLink', entity: ServiceLink },
        managementContract: { type: 'Doc', entity: Doc },
        meterTypesOnDirectContract: { type: 'MeterType[]', entity: MeterType, default: [] },
        order: { type: 'int' },
        residentialComplex: { type: 'ResidentialComplex', entity: ResidentialComplex },
        sector: { type: 'SectorLink', entity: SectorLink },
        techDocumentation: { type: 'Doc[]', entity: Doc, default: [] },
        wikiLink: { type: 'string' },
    },
}

class Building extends Entity {
    get schema() {
        return BuildingSchema
    }

    get dataMap() {
        return {
            building_type: 'buildingType',
            construction_date: 'constructionDate',
            construction_progress: 'constructionProgress',
            construction_start_date: 'constructionStartDate',
            date_finish_acceptance: 'dateFinishAcceptance',
            date_start_acceptance: 'dateStartAcceptance',
            deleted_at: 'deletedAt',
            deleted_by: 'deletedBy',
            is_active: 'isActive',
            finish_date: 'finishDate',
            internal_name: 'internalName',
            is_allowed_manual_edition_owners: 'isAllowedManualEditionOwners',
            is_available_for_account_payment: 'isAvailableForAccountPayment',
            is_done: 'isDone',
            key_service: 'keyService',
            management_contract: 'managementContract',
            meter_types_on_direct_contract: 'meterTypesOnDirectContract',
            residential_complex: 'residentialComplex',
            tech_documentation: 'techDocumentation',
            wiki_link: 'wikiLink',
            coordinates: 'coordinates',
        }
    }

    static fieldLabels = {
        address: 'Адрес',
        dateStartAcceptance: 'Дата начала приемки',
        dateFinishAcceptance: 'Дата окончания приемки',
        buildingType: 'Тип здания',
        city: 'Город',
        coordinates: 'Координаты дома',
        lat: 'Широта',
        lon: 'Долгота',
        compaines: 'Компании',
        constructionDate: 'Срок сдачи',
        constructionProgress: 'Готовность здания (%)',
        constructionStartDate: 'Дата начала строительства',
        finishDate: 'Дата окончания строительства',
        gp: 'ГП',
        id: 'ID',
        image: 'Изображение',
        internalName: 'Внутреннее название',
        isAvailableForAccountPayment: 'Доступна оплата',
        isDone: 'Готовность',
        keyService: 'Сервис выдачи ключей',
        managementContract: 'Договор управления',
        meterTypesOnDirectContract: 'Типы счётчиков на прямом договоре',
        order: 'Порядок',
        residentialComplex: 'ЖК',
        sector: 'Сектор',
        techDocumentation: 'Техническая документация',
        wikiLink: 'Ссылка на карточку дома в wiki',
    }

    toServer() {
        let coordinates
        if (this.coordinates?.lat && this.coordinates?.lon) {
            coordinates = `${this.coordinates.lat}:${this.coordinates.lon}`
        }

        return {
            date_start_acceptance: this.dateStartAcceptance ? moment.unix(this.dateStartAcceptance) : null,
            date_finish_acceptance: this.dateFinishAcceptance ? moment.unix(this.dateFinishAcceptance) : null,
            building_type: this.buildingType.id,
            city: this.city?.id || null,
            coordinates,
            construction_date: this.constructionDate,
            construction_start_date: this.constructionStartDate,
            finish_date: this.finishDate,
            gp: this.gp,
            image: this.image?.id || null,
            internal_name: this.internalName,
            is_available_for_account_payment: this.isAvailableForAccountPayment,
            key_service: this.keyService?.id || null,
            management_contract: this.managementContract?.id,
            meter_types_on_direct_contract: _filter(_map(this.meterTypesOnDirectContract, (item) => item?.id), (o) => !!o),
            residential_complex: this.residentialComplex?.id || null,
            sector: this.sector?.id || null,
            tech_documentation: _map(this.techDocumentation, (document) => document.id),
            wiki_link: this.wikiLink || null,
        }
    }

    get displayName() {
        return this.address?.short || this.address?.full || 'Адрес отсутствует'
    }
}

export default Building
