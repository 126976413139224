import Entity from '_entities/base/EntityNew'

import Address from '_entities/address/Address'

export const BuildingLinkSchema = {
    name: 'BuildingLink',
    primaryKey: 'id',
    properties: {
        address: { type: 'Address', entity: Address },
        id: { type: 'string' },
    },
}

class BuildingLink extends Entity {
    get schema() {
        return BuildingLinkSchema
    }

    static fieldLabels = {
        address: 'Адрес',
        id: 'ID',
    }

    get displayName() {
        return this.address?.short || this.address?.full || 'Адрес отсутствует'
    }
}

export default BuildingLink
